import(/* webpackMode: "eager" */ "/home/runner/work/evas-next/evas-next/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Title","Button"] */ "/home/runner/work/evas-next/evas-next/node_modules/rizzui/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/about-us.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/banner-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/contact-us.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/featured-articles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/featured-products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/evas-next/evas-next/src/components/featured-services.tsx");

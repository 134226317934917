'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import { Title, Tooltip } from 'rizzui';
import { Button } from 'rizzui';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSetting } from '@/providers/SettingsProvider';
import { IProduct } from '@/types/product';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

const FeaturedProducts = ({ products }: { products: IProduct[] }) => {
    const settings = useSetting();
    const sliderRef = useRef<null>(null);

    return (
        <div className="flex flex-col w-full">
            <div
                id="feature_product_title"
                className="flex items-center justify-center h-[100px]  bg-gray-100"
            >
                <Title className="font-thin tracking-[.3rem]">
                    Featured Product
                </Title>
            </div>
            <div className="featured-product_list">
                <div className="bg-white">
                    <div className="relative px-8 py-4" ref={sliderRef}>
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={1}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                            }}
                            navigation={{
                                enabled: true,
                                prevEl: '.custom-product-prev',
                                nextEl: '.custom-product-next',
                            }}
                            modules={[Autoplay, Navigation]}
                            loop
                            autoplay={{
                                delay:
                                    parseInt(settings.site.swiper_delay) * 1000,
                                disableOnInteraction: true,
                            }}
                        >
                            {products.map((product) => (
                                <SwiperSlide key={product.id}>
                                    <div className="m-2 rounded-lg shadow-md">
                                        <div className="w-full h-56">
                                            <Image
                                                width={400}
                                                height={300}
                                                src={product.media.path}
                                                alt={product.title}
                                                className="object-cover w-full h-full"
                                            />
                                        </div>
                                        <div className="p-2">
                                            <Tooltip content={product.title}>
                                                <p className="text-xl  tracking-widest text-gray-500 truncate">
                                                    {product.title}
                                                </p>
                                            </Tooltip>
                                            <p className="mt-2 text-sm  text-gray-500 truncate">
                                                {product.excerpt}
                                            </p>
                                            <Link
                                                aria-label={`View product ${product.title}`}
                                                href={`/products/${product.slug}`}
                                                className="tracking-[.1rem] text-gray-900 "
                                            >
                                                <Button
                                                    aria-label={`View product ${product.title}`}
                                                    variant="outline"
                                                    className="mt-3"
                                                >
                                                    More info
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <button
                            aria-label="Prev products"
                            className="absolute z-10 hidden text-white transform -translate-y-1/2 md:block custom-product-prev top-1/2 left-4 sm:left-2 md:left-4 lg:left-12"
                        >
                            <FaCircleChevronLeft className="w-10 h-10" />
                        </button>
                        <button
                            aria-label="Next products"
                            className="absolute z-10 hidden text-white transform -translate-y-1/2 md:block custom-product-next top-1/2 right-4 sm:right-2 md:right-4 lg:right-12"
                        >
                            <FaCircleChevronRight className="w-10 h-10" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedProducts;
